import React, { useEffect } from "react";

import PageHelmet from "../PageHelmet";

import "./New4.css";

import photo1 from "../../images/new4-1.webp";

const New4 = ({ handleHideNew4 }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className="new-4" id="new-4">
      <PageHelmet
        title="Выставка «Волшебная зима»"
        description=" Выставка коллектива молодых Московских художников. Коллектив существует в формате свободной мастерской."
        keywords="московский художник, художественная выставка, продажа картин, Галина Левченко, молодой художник"
        author="Галина Левченко"
        copyright="Copyright © 2024 Artist Galina Levchenko"
        image={photo1}
        canonicalUrl="https://galinalevchenko.ru/new-4"
      />
      <div className="new-1__wrapper">
        <p className="new-1__text">
          Выставка посвящается уходящей зиме. В этом году выдалась не обычная
          зима, очень теплая и не привычная для нас.
          <br />
          <br />
          Но мы решили насладиться последним глотком зимы и представляем вашему
          вниманию наши картины. На которых изображены красивые зимние пейзажи,
          напоминающие нам о характере Русской зимы. Есть сюжеты в новогодних
          натюрмортах, рассказывающие о традиции дарить подарки украшать
          пространство дома. Загадывать желания и писать заветные письма деду
          морозу.
          <br />
          <br /> О разнообразии традиций празднования Нового года, и проводов
          зимы на масленицу вы увидите картинах масленичные гуляния и катания на
          санях. Эта выставка объединяет разные стили и техники живописи,
          позволяя зрителям увидеть мир глазами талантливых художников.
          <br />
          <br />
          Однажды объединившихся, в школе живописи в дальнейшем продолжили
          писать картины в творческой мастерской совместно со своим
          преподавателем, чтобы вместе творить и нести в мир красоту.
        </p>
        <div className="new-1__main">
          <p className="new-1__main-partners">
            На выставке будут представлены картины
            <br /> Галины Левченко,
            <br /> Светланы Кутузововой,
            <br /> Анны Рынковой,
            <br /> Лилии Смирновой,
            <br /> Любови Ерохиной,
            <br /> Екатерины Тонких,
            <br /> Анны Каторжновой,
            <br /> Валерии Нижниковой,
            <br /> Марии Ладановой.
          </p> </div>
          <p className="new-1__text">
            Приходите на нашу выставку, чтобы насладиться красотой зимней
            природы, почувствовать себя частью сказки и зарядиться
            позитивным настроением.
          </p>
          <button className="new-1__btn hover" onClick={handleHideNew4}>
            СВЕРНУТЬ
          </button>
       
      </div>
    </section>
  );
};

export default New4;
