import React, { useEffect } from "react";

import PageHelmet from "../PageHelmet";

import photo1 from "../../images/photoExhibition1.png";

const New5 = ({ handleHideNew5 }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className="new-5" id="new-5">
      <PageHelmet
        title='Выставка "скоро". Галина Левченко.'
        description="Выставка коллектива молодых Московских художников. Коллектив существует в формате свободной мастерской."
        keywords="московский художник, художественная выставка, продажа картин, Галина Левченко, молодой художник"
        author="Галина Левченко"
        copyright="Copyright © 2025 Artist Galina Levchenko"
        image={photo1}
        canonicalUrl="https://galinalevchenko.ru/news"
      />
      <div className="new-2__wrapper">
        <p className="new-2__text">
          Выставка проходит по адресу: Дом культуры "Юность" в ТРЦ "Место встречи
          Орион", Москва, Улица Лётчика Бабушкина, 26.
          <br />
          Время работы:
          <br />
          Понедельник — пятница с 14:00 до 18:00
        </p>
        <div className="new-2__main">
          <p className="new-2__main-partners">
            УЧАСТНИКИ:
            <br /> Евгения Сылка
            <br /> Анна Каторжнова
            <br /> Анна Рынкова
            <br /> Галина Левченко
            <br /> Екатерина Тонких
            <br /> Любовь Ерохина
            <br /> Людмила Скакун
            <br /> Мария Ладанова
            <br /> Марина Климова
            <br /> Михаил Абрамов 
            <br /> Ольга Шишацкая
            <br /> Светлана Кутузова
          </p>
          <button className="new-2__btn hover" onClick={handleHideNew5}>
            СВЕРНУТЬ
          </button>
        </div>
      </div>
    </section>
  );
};

export default New5;
